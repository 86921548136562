import { View } from "react-native";
import { pressReplayParams } from "../../../../../utils/nav";
import { RLink } from "../../../../RLink/RLink";
import RText from "../../../../RText";
import variables from "../../../../../styles/variables";
import { Tip as TipT, Tipster } from "../../../../../types/tipster";
import { useAtom } from "jotai";
import { routeAndPathAtom } from "../../../../../atoms";
import Icon from "../../../../Icon";
import Gap from "../../../../Gap";
import ShareButton from "../../../../Buttons/ShareButton";

const { spacing } = variables;

type Props = {
  tip: TipT;
  tipster?: Tipster;
};

const TipComment = ({ tip, tipster }: Props) => {
  const [routeAndPath] = useAtom(routeAndPathAtom);

  function addFullStop(comment: string) {
    return comment[comment.length - 1] !== "." ? comment + "." : comment;
  }

  // if the tip is displayed in a news article, then don't show the tip description since the tip is already explained in the article
  if (routeAndPath.route === "NewsPost") return;

  return (
    <View style={{ marginHorizontal: spacing.xxsmall }}>
      {tip.description && (
        <RText style={{ flexWrap: "wrap" }}>
          {addFullStop(tip.description)}{" "}
        </RText>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          height: 40,
          justifyContent: "space-between",
        }}
      >
        {!!tip.runner.last_replay && (
          <View style={{ flexDirection: "row" }}>
            <RLink
              to={{ ...pressReplayParams(tip.runner.last_replay) }}
              shouldOpenInNewTab
              size="sm"
            >
              Watch last run
            </RLink>
            <Gap size="xxsmall" />
            <Icon name="playCircled" size="medium" />
          </View>
        )}
        <ShareButton
          type="Tip"
          shareData={{
            title: `Check out this tip from ${tipster?.name?.formatted}`,
            text: tip.description,
            url: `tips`,
          }}
        />
      </View>

      {tip.expert_eye_comment && (
        <RText style={{ marginTop: spacing.xxsmall }}>
          {addFullStop(tip.expert_eye_comment)}{" "}
        </RText>
      )}
    </View>
  );
};

export default TipComment;
