import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import { FRONTEND_URL, fetchPost } from "..";
import { APIResponse } from "../../types/responses/APIResponse";
import { Dimensions, Platform } from "react-native";
import { buildPath } from "../../utils/buildPath";
import linking from "../../components/Navigation/URLMapper";
import FullStory from "../../services/FullStory";
import { sendAnalyticsEventAsync } from "../firebase/analytics";

export const postPageView = async (
  width: string,
  height: string,
  url: string,
  route: string
): Promise<APIResponse<any>> => {
  try {
    return fetchPost(
      "onspace/analytics/tracking/page-view",
      {},
      {
        session: {
          width: width,
          height: height,
        },
        page: {
          url: url,
          route: route.replaceAll("?", ""),
        },
      }
    );
  } catch (e) {
    console.log("error tracking pageview: ", route);
  }
};

export const trackPageView = (
  navRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
) => {
  let url = `${FRONTEND_URL}${buildPath(navRef)}`;
  let path =
    linking.config.screens[navRef.getCurrentRoute()?.name]?.path ||
    linking.config.screens[navRef.getCurrentRoute()?.name];

  const homeUrl = `${FRONTEND_URL}/`;

  if (url == homeUrl) {
    url = `${FRONTEND_URL}/home`;
    path = "/home";
  }
  // debugger;
  return postPageView(
    // screenWidth,
    Dimensions.get("window").width.toString(),
    Dimensions.get("window").height.toString(),
    url,
    path
  );
};

export const logEvent = async ({ key, data }: { key: string; data: any }) => {
  try {
    FullStory.event(key, data);
    console.log(`[FullStory] sent ${key} event with: `, data);
  } catch (e) {
    console.log(`[FullStory] failed to send ${key} event with ${data}: `, e);
  }

  const dataWithTimestamp = { ...data, timestamp: Date.now() };
  try {
    await sendAnalyticsEventAsync(key, dataWithTimestamp);
    console.log(`[GA] sent ${key} event with: `, dataWithTimestamp);
  } catch (e) {
    console.log(
      `[GA] failed to send ${key} event with ${dataWithTimestamp}: `,
      e
    );
  }
};
