import Constants, { ExecutionEnvironment } from "expo-constants";
import { Platform, View, StyleSheet } from "react-native";
import variables from "../../styles/variables";
import { CastButtonProps } from "./RCastButton";
import FullStory from "../../services/FullStory";
import { logEvent } from "../../client/analytics/trackPageView";
const isBareApp = Constants.executionEnvironment === ExecutionEnvironment.Bare;
const isWebApp = Platform.OS === "web";
let RNAP;
if (isBareApp && !isWebApp) {
  RNAP = require("react-airplay");
}

export default function RAirplayButton({
  style = {},
  size = "small",
}: CastButtonProps) {
  if (RNAP) {
    const {
      AirplayButton,
      showRoutePicker,
      useAirplayConnectivity,
      useAvAudioSessionRoutes,
      useExternalPlaybackAvailability,
    } = RNAP;

    return (
      <View style={style}>
        <AirplayButton
          style={variables.sizes[size]}
          prioritizesVideoDevices={true}
          tintColor="white"
          activeTintColor={variables.colors.palette.rtv.primary}
          onPress={() => {
            logEvent({
              key: "watch-airplay",
              data: {
                tag: "watch-airplay",
              },
            });
          }}
        />
      </View>
    );
  } else return null;
}
