import { useEffect } from "react";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { getInitialURL, parse } from "expo-linking";
import { addEventListener as linkingAddEventListener } from "expo-linking";
import { nativeNavFromSlug } from "../components/Promotion/Utils";

const useDeepLink = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (Platform.OS === "web") return;

    const handleDeepLink = (event) => {
      console.log(`[DeepLink] Received Deep Link:: ${JSON.stringify(event)}`);
      let data = parse(event.url);
      if (data.path) {
        const { screen, paramsObj } = nativeNavFromSlug(data.path);
        navigation?.navigate(screen, paramsObj);
      }
    };

    // Check if the app was opened by a deep link
    getInitialURL().then((url) => {
      if (url) handleDeepLink({ url });
    });

    // Listen for incoming links
    const urlEventListener = linkingAddEventListener("url", handleDeepLink);

    // Cleanup the event listener
    return () => {
      urlEventListener.remove();
    };
  }, [navigation]);
};

export default useDeepLink;
