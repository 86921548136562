import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import variables from "../../styles/variables";
import Gap from "../Gap";
import Icon from "../Icon";
import RText from "../RText";
import { RLink } from "../RLink/RLink";

// virtually identical to LiveButton.tsx, except its a link, i.e onPress takes you to watch, rather than
// attempts to play something.

export default function LiveButtonLink({
  openPlayChannel = false,
}: {
  openPlayChannel: boolean;
}) {
  const hoverRef = React.useRef();

  const handleMouseEnter = () => {
    if (hoverRef.current && Platform.OS === "web") {
      hoverRef.current.style.backgroundColor =
        variables.colors.palette.buttons.default.hovered.backgroundColor;
    }
  };

  const handleMouseLeave = () => {
    if (hoverRef.current && Platform.OS === "web") {
      hoverRef.current.style.backgroundColor =
        variables.colors.palette.rtv.secondary; // Revert style
    }
  };

  const link = openPlayChannel
    ? { screen: "watch", params: { channel: "play-tv" } }
    : { screen: "watch" };

  return (
    <RLink
      to={link}
      ref={hoverRef}
      pressableStyle={styles.buttonRound}
      customTextStyle={{ display: "none" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <View style={styles.livePlayContentContainer}>
        <RText
          family="secondary"
          weight="bold"
          size="md"
          color="white"
          style={styles.liveText}
        >
          Live
        </RText>
        <Gap size="xxsmall" />
        <Icon name="play" size="medium" />
      </View>
    </RLink>
  );
}

const styles = StyleSheet.create({
  buttonRound: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    height: 40,
    backgroundColor: variables.colors.palette.rtv.secondary,
    minWidth: 85,
  },
  livePlayContentContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  liveText: {
    textTransform: "uppercase",
    padding: 10,
    paddingRight: 0,
    paddingLeft: 5,
  },
});
