import { storage } from "./storage";

// Full Story tags
export const tagName = {
  "Racing TV": "main-stream",
  "Racing TV extra 1": "extra1-stream",
  "Racing TV extra 2": "extra2-stream",
  "Racing TV extra 3": "extra3-stream",
  "Racing TV extra 4": "extra4-stream",
  "Racing TV Play": "rtvplay-stream",
};

//Functions to save Full Story tags to save in storage
export const saveLastStreamWatchedToStorage = async (stream: string) => {
  await storage.save({
    key: "FS-LAST-STREAM-WATCHED",
    data: stream,
  });
  console.log(
    "[FullStory]: FS-LAST-STREAM-WATCHED key saved to storage: ",
    stream
  );
};

export const getLastStreamWatchedFromStorage = async () => {
  try {
    const lastStreamWatched = await storage.load({
      key: "FS-LAST-STREAM-WATCHED",
    });
    console.log("[FullStory]: FS-LAST-STREAM-WATCHED key loaded from storage");
    return lastStreamWatched;
  } catch (e) {
    console.log("No FS-LAST-STREAM-WATCHED key found");
  }
};

export const removeLastStreamWatchedFromStorage = async () => {
  try {
    await storage.remove({
      key: "FS-LAST-STREAM-WATCHED",
    });
    console.log("[FullStory]: FS-LAST-STREAM-WATCHED key removed from storage");
  } catch (e) {
    console.log("No FS-LAST-STREAM-WATCHED key to remove");
  }
};
