import { fetchGet } from "../..";
import { Channel } from "../../../components/Screens/Watch/Live/LiveStreamSection";
import { APIResponse } from "../../../types/responses/APIResponse";
import { MemberPlans } from "../../../types/memberPlans";
import {
  AlternateSlot,
  AlternateSlots,
  ScheduleSlot,
  ScheduleSlotItem,
} from "../../../types/tvSchedule";
import { Player } from "../../../types/replay";
import {
  PerformAPIResponse,
  Stream,
  StreamDataResponse,
  StreamUrlParams,
} from "../../../types/stream";
import { cleanSlug } from "../../../utils/cleanSlug";
import { getDeviceId } from "../../../utils/getDeviceId";
import { APPLE_PAY_AVAILABLE } from "../../../utils/flags";

const outletAuthKey = "18arele3k5ry51xfcefkic6f5o"; // Nurun (website and mobile apps) - We'll implement other platforms later

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
} as const;

const transformSlots = (
  slots: ScheduleSlot[] | AlternateSlot[],
  type: "main-channel" | "alternate-channel"
): ScheduleSlotItem[] => {
  return slots
    ?.map((slot) => {
      const { datetime, episode, id } = slot;
      return {
        title: episode.programme.title,
        description: episode.epg_synopsis,
        startAt: datetime.start,
        id,
        type,
      } as ScheduleSlotItem;
    })
    .filter(Boolean);
  // .flat();
};

export const getUpNext = async (
  skipFirstProgram = true
): Promise<ScheduleSlotItem[]> => {
  const { schedule_slots, alternate_slots } = (await fetchGet(
    "videos/streams"
  )) as {
    schedule_slots: ScheduleSlot[];
    alternate_slots: AlternateSlots;
  };

  const mainChannelPrograms = transformSlots(schedule_slots, "main-channel");

  const alternateChannelPrograms =
    transformSlots(
      Object.values(alternate_slots || {})[0],
      "alternate-channel"
    ) ?? [];

  return [...mainChannelPrograms, ...alternateChannelPrograms];
};

export const getLiveChannelsInfo = async (): Promise<Channel[]> => {
  const videos = (await fetchGet("videos/streams")) as {
    schedule_slots: ScheduleSlot[];
    streams: Stream[];
  };
  const { schedule_slots, streams: allStreams } = videos;

  const channels: Channel[] = [];

  const activeStreams: Stream[] = allStreams.filter(
    (s) => s.active.state === "on_until"
  );

  activeStreams?.forEach((stream, index) => {
    const { meeting } = stream;
    // RUK 24/7 event
    if (index == 0) {
      channels.push({
        name: "Racing TV",
        title: "Main live channel",
        id: undefined,
        sourceEventType: undefined,
        programmeTitle: schedule_slots[0].episode.programme.title,
        programmeDescription: schedule_slots[0].episode.epg_synopsis,
        backgroundImage:
          stream.preview_image_url || allStreams[0].placeholder_image_url,
        startTime: new Date(
          schedule_slots[0].datetime.start
        ).toLocaleTimeString("en-GB", timeOptions),
        endTime: new Date(schedule_slots[0].datetime.end).toLocaleTimeString(
          "en-GB",
          timeOptions
        ),
        streamData: stream.stream_data,
        free: stream.free,
      });
      // if there is no stream data and the source event type is alternate
      // then it is Racing TV Play
    } else if (
      !stream.stream_data &&
      stream.source_event_type === "alternate"
    ) {
      channels.push({
        name: `Racing TV Play`,
        title: stream.title,
        id: stream.id,
        sourceEventType: stream.source_event_type,
        programmeTitle: stream.title,
        programmeDescription:
          "Racing TV Play – brilliant action from Racing TV 24 hours a day",
        backgroundImage:
          stream.preview_image_url || stream.placeholder_image_url,
        streamData: stream.stream_data,
        free: stream.free,
      });
    }
    // other active streams
    else {
      channels.push({
        name: `Racing TV extra ${index}`,
        title: meeting?.track?.name
          ? `${meeting?.track?.name} live`
          : stream.title,
        id: stream.id,
        sourceEventType: stream.source_event_type,
        slug: meeting?.track?.slug,
        programmeTitle: meeting?.track?.name
          ? `${meeting?.track?.name}`
          : stream.title,
        programmeDescription: null, // No description for extra streams - https://www.pivotaltracker.com/story/show/185774281
        backgroundImage:
          stream.preview_image_url || stream.placeholder_image_url,
        streamData: stream.stream_data,
      });
    }
  });

  return channels;
};

export const checkFreeVideoPeriod = async (): Promise<APIResponse<Player>> => {
  return await fetchGet(`member/watch/streams/channel`);
};

const getStreamUrl = async (
  StreamUrlParams: StreamUrlParams
): Promise<string> => {
  const { baseUrl, outletAuthKey, streamUuid, format, queryParameters, token } =
    StreamUrlParams;
  const url = `${baseUrl}/${outletAuthKey}/${streamUuid}?_fmt=${format}&_fld=${queryParameters}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Origin: "https://www.racingtv.com",
        Referer: "https://www.racingtv.com/",
      },
    });

    if (!response.ok) {
      console.error(
        "Client/Watch/Live::getStreamUrl - Error fetching stream url",
        response.statusText
      );
      return "";
    }

    const data: PerformAPIResponse = await response.json();
    const playerAlias = "iPhonesec";
    const streamLauncher = data.launchInfo.streamLauncher.find(
      (launcher) => launcher.playerAlias === playerAlias
    );

    if (!streamLauncher) {
      console.error(
        "Client/Watch/Live::getStreamUrl - Error fetching stream url",
        "No streamLauncher found"
      );
      return "";
    }

    return streamLauncher.launcherURL;
  } catch (error) {
    console.error(
      "Client/Watch/Live::getStreamUrl - Error fetching stream url",
      error
    );
    return "";
  }
};

const responseWithDeviceId = async (url) => {
  const deviceId = await getDeviceId();

  return fetchGet(
    url,
    {},
    false,
    APPLE_PAY_AVAILABLE
      ? {
          "DEVICE-IDENTIFIER": deviceId,
        }
      : {}
  );
};

const requestWithoutDeviceId = async (url: string) => {
  try {
    return await fetchGet(url);
  } catch (error) {
    return null;
  }
};

const fetchSource = async (
  url: string,
  checkingFreeVidPeriod: boolean = false
): Promise<string> => {
  try {
    const responseWithoutDeviceId = await requestWithoutDeviceId(url);

    // we do this because API will fail if we use device id and there's not a
    // apple pay purchase associated with it. so we only want to fallback to that.
    const stream: StreamDataResponse = responseWithoutDeviceId?.stream
      ? responseWithoutDeviceId.stream
      : (await responseWithDeviceId(url)).stream;

    if (checkingFreeVidPeriod) {
      const freeVidPeriod = stream?.free_period_end;
      if (freeVidPeriod) {
        return freeVidPeriod;
      } else {
        return undefined;
      }
    }

    const {
      data: {
        base_url,
        asset_id,
        params: { _fmt, _fld },
        token,
      },
    } = stream;

    // Now we have data we can build Stats Perform URL and fetch it to get the stream URL
    const streamURL = await getStreamUrl({
      baseUrl: base_url,
      outletAuthKey,
      streamUuid: asset_id,
      format: _fmt,
      queryParameters: _fld,
      token: token,
    } as StreamUrlParams);

    return streamURL;
  } catch (error) {
    console.error(
      "Client/Watch/Live::fetchSource - Error fetching source",
      error
    );
    return "";
  }
};

export const getLiveChannelsSource = async (
  channel?: Channel,
  checkFreeVidPeriod?: boolean
): Promise<string> => {
  // ALTERNATE LIVE STREAM CHANNEL
  if (channel?.sourceEventType === "alternate") {
    if (!channel.streamData) {
      const {
        player: { sources },
      } = await fetchGet(`member/watch/streams/alternates/${channel.id}`);

      return sources[0]?.url;
    }
    return await fetchSource(
      `member/watch/streams/alternates/stream-data/${channel.id}`
    );
  }
  // MAIN LIVE STREAM CHANNEL
  if (!channel?.slug) {
    return await fetchSource(
      "member/watch/streams/channel/stream-data",
      checkFreeVidPeriod
    );
  }
  // EXTRA LIVE STREAM CHANNELS
  return await fetchSource(
    `member/watch/streams/tracks/stream-data/${cleanSlug(channel.slug)}`
  );
};

export const getMemberPlans = async (): Promise<APIResponse<MemberPlans>> => {
  return await fetchGet(`member/plans`);
};
