import { Screens, getPathFromScreen } from "../../Navigation/URLMapper";

function formatSearchTerm(searchTerm: string) {
  //remove hyphens, make lowercase, remove spaces and change rtv/RTV to racing tv
  let formattedSearchTerm = searchTerm
    .replace(/[-\s]/g, "")
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/rtv/gi, "racingtv");

  return formattedSearchTerm;
}

function removeParams(path: string) {
  const index = path.indexOf(":");
  if (index !== -1) {
    return path.substring(0, index);
  }
  return path;
}

export const getPages = (searchTerm: string) => {
  return Object.fromEntries(
    Object.entries(pages).filter(
      ([key, page]) =>
        removeParams(getPathFromScreen(page.name).replace("-", "")).includes(
          formatSearchTerm(searchTerm)
        ) && page.searchable
    )
  );
};

export type Pages = {
  [Key in Screens]: {
    name: Key;
    label: string;
    searchable: boolean;
  };
};

export const pages: Pages = {
  Home: {
    name: "Home",
    label: "Home",
    searchable: true,
  },
  Watch: {
    name: "Watch",
    label: "Watch",
    searchable: true,
  },
  Replays: {
    name: "Replays",
    label: "Replays",
    searchable: true,
  },
  IndividualReplay: {
    name: "IndividualReplay",
    label: "Individual Replay",
    searchable: false,
  },
  OnDemand: {
    name: "OnDemand",
    label: "On Demand",
    searchable: true,
  },
  CombinedOnDemand: {
    name: "CombinedOnDemand",
    label: "Combined On Demand",
    searchable: false,
  },
  IndividualOnDemand: {
    name: "IndividualOnDemand",
    label: "Individual On Demand",
    searchable: false,
  },
  CatchUp: {
    name: "CatchUp",
    label: "Catch Up",
    searchable: true,
  },
  TvSchedule: {
    name: "TvSchedule",
    label: "TV Schedule",
    searchable: true,
  },
  RtvPlaySchedule: {
    name: "RtvPlaySchedule",
    label: "Racing TV Play Schedule",
    searchable: false,
  },
  Racecards: {
    name: "Racecards",
    label: "Racecards",
    searchable: true,
  },
  // Antepost: {
  //   name: "Antepost",
  //   label: "Antepost",
  //   searchable: true,
  // },
  IndividualRacecard: {
    name: "IndividualRacecard",
    label: "Individual Racecard",
    searchable: false,
  },
  // IndividualAntepostRacecard: {
  //   name: "IndividualAntepostRacecard",
  //   label: "Individual Antepost Racecard",
  //   searchable: false,
  // },
  NonRunners: {
    name: "NonRunners",
    label: "Non Runners",
    searchable: true,
  },
  TodaysRunners: {
    name: "TodaysRunners",
    label: "Today's Runners",
    searchable: true,
  },
  TomorrowsRunners: {
    name: "TomorrowsRunners",
    label: "Tomorrow's Runners",
    searchable: true,
  },
  Results: {
    name: "Results",
    label: "Results",
    searchable: true,
  },
  IndividualResult: {
    name: "IndividualResult",
    label: "Individual Result",
    searchable: false,
  },
  FastResults: {
    name: "FastResults",
    label: "Fast Results",
    searchable: true,
  },
  RTVTipsters: {
    name: "RTVTipsters",
    label: "Racing TV Tipsters",
    searchable: true,
  },
  // AllTips: {
  //   name: "AllTips",
  //   label: "All Tips",
  //   searchable: true,
  // },
  NapOfTheDay: {
    name: "NapOfTheDay",
    label: "Nap of the Day",
    searchable: true,
  },
  IndividualTipster: {
    name: "IndividualTipster",
    label: "Individual Tipster",
    searchable: false,
  },
  IndexOrProfile: {
    name: "IndexOrProfile",
    label: "Profiles",
    searchable: true,
  },
  AllNews: {
    name: "AllNews",
    label: "All News",
    searchable: true,
  },
  Latest: {
    name: "Latest",
    label: "Latest",
    searchable: true,
  },
  Highlights: {
    name: "Highlights",
    label: "Highlights",
    searchable: true,
  },
  MostViewed: {
    name: "MostViewed",
    label: "Most Viewed",
    searchable: true,
  },
  Columnists: {
    name: "Columnists",
    label: "Columnists",
    searchable: true,
  },
  IndividualColumnist: {
    name: "IndividualColumnist",
    label: "Individual Columnist",
    searchable: false,
  },
  NewsPost: {
    name: "NewsPost",
    label: "News Post",
    searchable: false,
  },
  Benefits: {
    name: "Benefits",
    label: "Benefits",
    searchable: true,
  },
  RtvExtra: {
    name: "RtvExtra",
    label: "Racing TV Extra",
    searchable: true,
  },
  Offers: {
    name: "Offers",
    label: "Offers",
    searchable: true,
  },
  JoinOffer: {
    name: "JoinOffer",
    label: "Join Offer",
    searchable: false,
  },
  RacecourseOffers: {
    name: "RacecourseOffers",
    label: "Racecourse Offers",
    searchable: true,
  },
  Rewards4Racing: {
    name: "Rewards4Racing",
    label: "Rewards4Racing",
    searchable: true,
  },
  ClubDays: {
    name: "ClubDays",
    label: "Club Days",
    searchable: true,
  },
  Tracker: {
    name: "Tracker",
    label: "Tracker",
    searchable: true,
  },
  Club: {
    name: "Club",
    label: "Club",
    searchable: true,
  },
  Magazine: {
    name: "Magazine",
    label: "Magazine",
    searchable: true,
  },
  Account: {
    name: "Account",
    label: "Account",
    searchable: true,
  },
  ActivateDayPass: {
    name: "ActivateDayPass",
    label: "Activate Day Pass",
    searchable: true,
  },
  Competitions: {
    name: "Competitions",
    label: "Competitions",
    searchable: true,
  },
  Racecourses: {
    name: "Racecourses",
    label: "Racecourses",
    searchable: true,
  },
  Racecourse: {
    name: "Racecourse",
    label: "Racecourse",
    searchable: false,
  },
  Podcasts: {
    name: "Podcasts",
    label: "Podcasts",
    searchable: true,
  },
  Podcast: {
    name: "Podcast",
    label: "Podcast",
    searchable: false,
  },
  ContactUs: {
    name: "ContactUs",
    label: "Contact Us",
    searchable: true,
  },
  HelpCentre: {
    name: "HelpCentre",
    label: "Help Centre",
    searchable: true,
  },
  General: {
    name: "General",
    label: "General",
    searchable: true,
  },
  Sky: {
    name: "Sky",
    label: "Sky",
    searchable: true,
  },
  Online: {
    name: "Online",
    label: "Online",
    searchable: true,
  },
  Extra: {
    name: "Extra",
    label: "Extra",
    searchable: true,
  },
  IOS: {
    name: "IOS",
    label: "IOS",
    searchable: true,
  },
  Virgin: {
    name: "Virgin",
    label: "Virgin",
    searchable: true,
  },
  Vodafone: {
    name: "Vodafone",
    label: "Vodafone",
    searchable: true,
  },
  DayPass: {
    name: "DayPass",
    label: "Day Pass",
    searchable: true,
  },
  International: {
    name: "International",
    label: "International",
    searchable: true,
  },
  Mobile: {
    name: "Mobile",
    label: "Mobile",
    searchable: true,
  },
  ClubDaysHelp: {
    name: "ClubDaysHelp",
    label: "Club Days Help",
    searchable: true,
  },
  Android: {
    name: "Android",
    label: "Android",
    searchable: true,
  },
  Freeview: {
    name: "Freeview",
    label: "Freeview",
    searchable: true,
  },
  TermsAndConditions: {
    name: "TermsAndConditions",
    label: "Terms & Conditions",
    searchable: true,
  },
  PrivacyPolicy: {
    name: "PrivacyPolicy",
    label: "Privacy Policy",
    searchable: true,
  },
  RMGPressRelease: {
    name: "RMGPressRelease",
    label: "RMG Press Release",
    searchable: true,
  },
  Apps: {
    name: "Apps",
    label: "Apps",
    searchable: true,
  },
  FreeBets: {
    name: "FreeBets",
    label: "Free Bets",
    searchable: true,
  },
  ResponsibleGambling: {
    name: "ResponsibleGambling",
    label: "Responsible Gambling",
    searchable: true,
  },
  NotFound: {
    name: "NotFound",
    label: "Not Found",
    searchable: false,
  },
  ForgotPassword: {
    name: "ForgotPassword",
    label: "Forgot Password",
    searchable: true,
  },
  ResetPassword: {
    name: "ResetPassword",
    label: "Reset Password",
    searchable: true,
  },
  TvAppAuth: {
    name: "TvAppAuth",
    label: "TV App Auth",
    searchable: false,
  },
  HorseRacingCalendar: {
    name: "HorseRacingCalendar",
    label: "Horse Racing Calendar",
    searchable: true,
  },
  FestivalHub: {
    name: "FestivalHub",
    label: "Festival Hub",
    searchable: false,
  },
  IndividualCompetition: {
    name: "IndividualCompetition",
    label: "Individual Competition",
    searchable: false,
  },
};
