import { useEffect, useRef } from "react";
import { useNavigation } from "@react-navigation/native";
import * as Notifications from "expo-notifications";
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import {
  addNotificationResponseReceivedListener,
  removeNotificationSubscription,
  getLastNotificationResponseAsync,
} from "expo-notifications";
import { nativeNavFromSlug } from "../components/Promotion/Utils";
import { parseDeepLink } from "../utils/parseDeepLink";
import { ExpoNotification } from "../types/expoNotification";
import Constants, { ExecutionEnvironment } from "expo-constants";
import { registerMemberDevice } from "../client/firebase/device/registration";
const isBareApp = Constants.executionEnvironment === ExecutionEnvironment.Bare;

let messaging;
if (isBareApp) {
  messaging = require("@react-native-firebase/messaging").default;
}

async function requestNotificationPermissions(retryCount = 5, delay = 4000) {
  let hasNotificationPermissions = false;

  while (retryCount > 0) {
    try {
      const settings = await Notifications.getPermissionsAsync();
      hasNotificationPermissions =
        settings.granted ||
        settings.ios?.status ===
          Notifications.IosAuthorizationStatus.PROVISIONAL;

      if (!hasNotificationPermissions) {
        const requestSettings = await Notifications.requestPermissionsAsync();
        hasNotificationPermissions =
          requestSettings.granted ||
          requestSettings.ios?.status ===
            Notifications.IosAuthorizationStatus.PROVISIONAL;
      }

      if (hasNotificationPermissions) {
        break; // Permissions granted, exit the loop
      }
    } catch (e) {
      console.log("[Push Notifications] Error handling permissions:: ", e);
    }

    retryCount--;
    if (retryCount > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
    }
  }

  if (!hasNotificationPermissions) {
    console.error("Failed to request permissions after retries.");
  }

  return hasNotificationPermissions;
}

Notifications.setNotificationHandler({
  handleSuccess: (notificationIdentifier) => {
    // dismiss notification immediately after it is presented
    console.log(`NotificationID:::: ${JSON.stringify(notificationIdentifier)}`);
  },
  handleNotification: async (notification) => {
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
    };
  },
});

const usePushNotifications = () => {
  const notificationListener = useRef();
  const responseListener = useRef();
  const navigation = useNavigation();

  const handleNotificationResponse = (
    response: ExpoNotification | FirebaseMessagingTypes.RemoteMessage
  ) => {
    console.log(
      `[Push Notifications] Received Deep Link:: ${JSON.stringify(response)}`
    );
    const deepLink = parseDeepLink(response);
    if (deepLink) {
      fetch(deepLink.trackingUrl)
        .catch((e) => {})
        .finally(() => {
          try {
            const { screen, paramsObj } = nativeNavFromSlug(deepLink.url);
            navigation?.navigate(screen, paramsObj);
          } catch (e) {
            console.log(
              "[Push Notifications] Error navigating to deep link:: ",
              e
            );
          }
        });
    }
  };

  useEffect(() => {
    requestNotificationPermissions().then((hasPermission) => {
      if (hasPermission && messaging) {
        messaging()
          .getToken()
          .then(async (token) => {
            try {
              console.log("[Push Notifications] Device Token:: ", token);

              const resp = await registerMemberDevice(token);

              if (resp?.meta?.status === 200) {
                console.log(
                  "[Push Notifications] Device registered successfully:: ",
                  resp
                );
              }
            } catch (e) {
              console.log(
                "[Push Notifications] Error registering device:: ",
                e
              );
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    if (!messaging) return;
    // Handle notification 'response' (from FCM) from killed app state (known only for android)
    messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        if (remoteMessage) {
          handleNotificationResponse(remoteMessage);
        }
      });

    // Handle notification 'response' from background app state
    messaging().setBackgroundMessageHandler(async (remoteMessage) => {
      handleNotificationResponse(remoteMessage);
    });

    // Request notification permissions from user.
    messaging().requestPermission();

    // Listen to whether the token is refreshed
    messaging().onTokenRefresh(async (token) => {
      try {
        const resp = await registerMemberDevice(token);

        if (resp?.meta?.status === 200) {
          console.log(
            "[Push Notifications] Device registered successfully:: ",
            resp
          );
        }
      } catch (e) {
        console.log("[Push Notifications] Error refreshing device token:: ", e);
      }
    });

    // Handle notification from foreground app state. This will be a silent notifcation so we create
    // a new Expo Notification to show to the user. The notification response is then handled by
    // Expo's addNotificationResponseReceivedListener
    const unsubscribe = messaging().onMessage(async (remoteMessage) => {
      `Notification Response:::: ${JSON.stringify(remoteMessage, null, 2)}`;
      await Notifications.scheduleNotificationAsync({
        content: {
          title: remoteMessage?.notification?.title || "",
          body: remoteMessage?.notification?.body || "",
          sound: true,
          data: remoteMessage?.data,
        },
        trigger: null,
      });
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    getLastNotificationResponseAsync().then((response) => {
      if (response) {
        handleNotificationResponse(response);
      }
    });

    Notifications.useLastNotificationResponse;

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        console.log(
          `Notification:::: ${JSON.stringify(notification, null, 2)}`
        );
      });

    responseListener.current = addNotificationResponseReceivedListener(
      (response) => {
        console.log(
          `Notification Response:::: ${JSON.stringify(response, null, 2)}`
        );
        handleNotificationResponse(response);
      }
    );

    return () => {
      removeNotificationSubscription(responseListener.current);
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
    };
  }, []);

  // this appears to be superflous - as there's a similar useEffect in App.tsx.
  // If no issues in december 2024 onward - remove!
  // useEffect(() => {
  //   const handleDeepLink = (event) => {
  //     let data = parse(event.url);

  //     if (data.path) {
  //       const { screen, paramsObj } = nativeNavFromSlug(data.path);
  //       navigation?.navigate(screen, paramsObj);
  //     }
  //   };

  //   addEventListener("url", handleDeepLink);

  //   getInitialURL().then((url) => {
  //     if (url) handleDeepLink({ url });
  //   });

  //   return () => {
  //     removeEventListener("url", handleDeepLink);
  //   };
  // }, []);
};

export default usePushNotifications;
