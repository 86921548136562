import Constants, { ExecutionEnvironment } from "expo-constants";
import { useEffect } from "react";
import { Platform, View, ViewStyle, NativeModules } from "react-native";
import { IconSize } from "../Icon/Icon";
import variables from "../../styles/variables";
import FullStory from "../../services/FullStory";
import { logEvent } from "../../client/analytics/trackPageView";

const isBareApp = Constants.executionEnvironment === ExecutionEnvironment.Bare;
const isWebApp = Platform.OS === "web";
const isIos = Platform.OS === "ios";
const { RNGCDiscoveryManager: Native } = NativeModules;

let RNGC;
if (isBareApp && !isWebApp) {
  RNGC = require("react-native-google-cast");
}

export type CastButtonProps = {
  source?: string;
  title?: string;
  subtitle?: string;
  poster?: string;
  contentType?: string;
  streamDuration?: number;
  style?: ViewStyle | ViewStyle[];
  size?: IconSize;
  isLive?: boolean;
};

let sessionId: string = "";

export default function RCastButton({
  source,
  title = "",
  subtitle = "",
  poster = "",
  contentType,
  streamDuration,
  style = {},
  size = "small",
  isLive,
}: CastButtonProps) {
  if (RNGC) {
    const { CastButton, useCastState, useCastSession } = RNGC;
    const castState = useCastState();
    const castSession = useCastSession();

    useEffect(() => {
      if (castState === "noDevicesAvailable" && isIos) {
        Native.startDiscovery()
          .then(() => {
            console.log("[Casting] startDiscovery success");
          })
          .catch((err) => {
            console.log("[Casting] startDiscovery error: ", err);
          });
      }
      if (castState === "connected") {
        if (castSession?.id && sessionId !== castSession?.id) {
          sessionId = castSession?.id;
        } else return;
        if (castSession?.client && source) {
          if (isLive) {
            castSession.client
              .loadMedia({
                autoplay: true,
                mediaInfo: {
                  contentUrl: source,
                  streamType: "live",
                  duration: -1,
                  hlsSegmentFormat: "TS",
                  contentType: "application/x-mpegURL",
                  metadata: {
                    title: title ? title : "Main Live Channel",
                    studio: "Racing TV",
                    subtitle,
                    type: "movie",
                    images: [
                      {
                        url: poster,
                      },
                    ],
                  },
                },
              })
              .then(() => console.log("[Casting] loadMedia success"))
              .catch((err) => console.log("[Casting] loadMedia error: ", err));
          } else
            castSession.client
              .loadMedia({
                autoplay: true,
                mediaInfo: {
                  contentUrl: source,
                  contentType,
                  metadata: {
                    title,
                    studio: "Racing TV",
                    subtitle,
                    type: "movie",
                    images: [
                      {
                        url: poster,
                      },
                    ],
                  },
                  streamDuration,
                },
              })
              .then(() => console.log("[Casting] loadMedia success"))
              .catch((err) => console.log("[Casting] loadMedia error: ", err));
        }
      }
    }, [castState, castSession]);

    return (
      <View style={style}>
        <CastButton
          tintColor="white"
          style={size && variables.sizes[size]}
          onPress={() => {
            logEvent({
              key: "watch-cast",
              data: {
                tag: "watch-cast",
              },
            });
          }}
        />
      </View>
    );
  } else return null;
}
